import { Injectable } from '@angular/core';

@Injectable()
export class PagesService
{
 private pages:Page[] = [
  {
   id: 0,
   proyecto: "Motos",
   descripcion: "Nos preocupamos por tu moto, pero aún más por tu vida.",
   link: "https://motos.proyectoslab.com",
   img: "/assets/img/pages/motos.jpg"
  },
  {
   id: 1,
   proyecto: "LaMision",
   descripcion: "Protege lo que más quieres de los Lubrinocivos",
   link: "https://lamision.proyectoslab.com",
   img: "/assets/img/pages/lamision.jpg"
  },
  {
   id: 2,
   proyecto: "VirusAttack",
   descripcion: "No permitas que el covit-19 llegue a ti ¡Lávate las manos antes y después de iniciar esta aventura!",
   link: "https://virusattack.proyectoslab.com",
   img: "/assets/img/pages/virusattack.jpg"
  },
  {
   id: 4,
   proyecto: "Wacky Band",
   descripcion: "Juego interactivo de Cartoon Network con los personajes de Looney Tunes.",
   link: "https://wackyband.proyectoslab.com",
   img: "/assets/img/pages/wackyband.png"
  },
  {
   id: 5,
   proyecto: "Delfinderio",
   descripcion: "Sitio web donde se publican articulos acerca de los delfines en Colombia, Brasil, Bolivia, Ecuador y Perú.",
   link: "https://delfinderio.proyectoslab.com",
   img: "/assets/img/pages/delfinderio.png"
  },
  {
   id: 6,
   proyecto: "SmartFilms",
   descripcion: "SmartFilms es un festival de cine de películas realizadas con celular con la misión de democratizar el cine, haciendo uso de las nuevas plataformas digitales de comunicación y difusión.",
   link: "https://smartfilms.com.co",
   img: "/assets/img/pages/smartfilms.png"
  },
  {
   id: 7,
   proyecto: "GRB",
   descripcion: "GRB (Global Reinsurance Brokers) es una corporación dedicada al corretaje de reaseguros, ofreciendo diferentes alternativas en la colocación de contratos y facultativos.",
   link: "http://globalbrokers.biz",
   img: "/assets/img/pages/grb.png"
  },
  {
   id: 8,
   proyecto: "Nutreya",
   descripcion: "Nutreya, marca enfocada en productos nutritivos, de calidad y asequible para las personas.",
   link: "https://nutreya.proyectoslab.com",
   img: "/assets/img/pages/nutreya.png"
  },
  {
   id: 9,
   proyecto: "Claro Convergente",
   descripcion: "Plataforma web Claro Convergente.",
   link: "https://claroconvergente.proyectoslab.com",
   img: "/assets/img/pages/claro.png"
  }
 ];

 getPages():Page[]
 {
  return this.pages;
 }
}

export interface Page{
 id: number;
 proyecto: string;
 descripcion: string;
 link: string;
 img: string;
}
