import { Component, OnInit } from '@angular/core';
import { PagesService, Page } from '../../services/pages.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  
  public pages:Page[] = [];	

  constructor(private pagesService:PagesService){ }

  ngOnInit()
  {
   this.pages = this.pagesService.getPages();
  }

}
