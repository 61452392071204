import { Component, OnInit } from '@angular/core';
import { PagesService, Page } from '../../services/pages.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {

  public pages:Page[] = [];	

  constructor(private pagesService:PagesService) { }

  ngOnInit() 
  {
   this.pages = this.pagesService.getPages();
  }

}
